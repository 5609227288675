import React from "react";
import AdminNavbar from "./AdminNavbar";

import axios from 'axios';
class ManageCourse extends React.Component
{
  constructor(prop)
  {
    super(prop)
  }
  state={
    data:[],
    users:[],
    url:"http://localhost:8080/backend/deleteCourse.php?q="
  }

  componentDidMount(){
    //get request

    
    axios.get('http://localhost:8080/backend/fetchinstructor.php').then(res => 
    {
    
    this.setState({users: res.data});
    console.log(this.state.users)
       }); 

    axios.get('http://localhost:8080/backend/manageCourse.php').then(res => 
    {
    
    this.setState({data: res.data});
    console.log(this.state.data)
       }); 
    
    }


  render()
  {
    return (
      <div className="amcpage_container">
        <div className="amcmain-container">
          <AdminNavbar />
          <div className="amccontent">
            <br />
            <div className="form">  
              <form id="admin_form" action="http://localhost:8080/backend/addcourse.php">
                <fieldset id="admc">
                  <legend>Add a New Course</legend>
                  <br />
                  <br />
                  <label htmlFor="courseName">Course Name</label>
                  <input type="text" id="courseName" name="courseName" required />
                  <br />
                  <br />
                  <label htmlFor="courseObjective">Course Objective</label>
                  <textarea
                    id="description"
                    name="courseObjective"
                    rows={8}
                    cols={40}
                  ></textarea>
                  <br />
                  <br />
                  <label htmlFor="instructorName">Instructor Name</label>
                  <select id="instructorName" name="instructorName">
                  {this.state.users.map((result) => {
            return (
                    <option value={result.name}>{result.name}</option>

                    )
                  })}
                  </select>
                  <br />
                  <br />
                  <label htmlFor="programName">Program Name</label>
                  <select id="programName" name="programName">
                    <option value="mscComputerScience"></option>
                    <option value="mscComputerScience">
                      MSC in Computer Science
                    </option>
                    <option value="mscDataScience">MSC in Data Science</option>
                    <option value="mscAI">MSC in Artificial Intelligence</option>
                  </select>
                  <br />
                </fieldset>
                <br />
                <button type="submit">Add Course</button>
              </form>
            </div>
            <div className="amctable">
              <table className="amcgrades-table">
                <thead>
                  <tr className="amcgrades-table-row">
                    <th className="amcgrades-table-header">Course Name</th>
                    <th className="amcgrades-table-header">Course Objective</th>
                    <th className="amcgrades-table-header">Instructor Name</th>
                    <th className="amcgrades-table-header">Program Name</th>
                    <th className="amcgrades-table-header">Action</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.data.map((result) => {
            return (
             
                 <tr className="amcgrades-table-row">
                  <td >{result.course_name}</td>
                  <td>{result.course_objective}</td>
                  <td>{result.instructor_name}</td>
                  <td >{result.program_name}</td>
                
                  <td ><a href={this.state.url+result.cid}><button type="submit">Delete</button></a></td>
                </tr>
             
            )
          })}
                
                
                  
                </tbody>
              </table>
            </div>
            <br />
            <br />
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default ManageCourse;
