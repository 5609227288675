import React from "react";
import QANavbar from "./QANavbar";
import axios from 'axios';
class QARecommendations extends React.Component {
  constructor(props)
  {
    super(props)
  }

  state={
    data:[]
  }

  componentDidMount(){
    //get request

    
    axios.get('http://localhost:8080/backend/fetchinstructor.php').then(res => 
    {
    
    this.setState({data: res.data});
    console.log(this.state.data)
       }); 

    
    
    }


  render()
  {
  return (
    <div className="qrecpage-container">
      <div className="qrecmain-container">
        <QANavbar />
        <div className="qreccontainer">
          <form action="http://localhost:8080/backend/recommend.php" >
            <fieldset id="info">
              <legend>Add Recommendations</legend>
              <br />
              <label htmlFor="instructorName">Instructor Name</label>
              <select id="instructorName" name="instructorName">
              {this.state.data.map((result) => {
            return (
                    <option value={result.name}>{result.name}</option>

                    )
                  })}
              </select>
              <br />
              <br />
              <label htmlFor="description">Recommendations:</label>
              <textarea
                id="description"
                name="address"
                rows={4}
                style={{ width: "auto" }}
              ></textarea>
              <br />
              <br />
            </fieldset>
            <br />
            <button type="submit">Submit</button>
          </form>
          <br />
          <br />
          <form className="feedback">
            <fieldset id="info" className="qaorec">
              <p>
                Here are recommendations for enhancing teaching methods,
                assessments, and program effectiveness:
              </p>
              <h3>Teaching Methods</h3>
              <ul>
                <li>Encourage interactive and participatory learning.</li>
                <li>Incorporate technology for engaging lessons.</li>
                <li>Regularly assess and adapt teaching strategies.</li>
              </ul>
              <h3>Assessments</h3>
              <p>To improve assessments:</p>
              <ol>
                <li>
                  <strong>Diversify Assessment Types:</strong> Include a mix of
                  quizzes, projects, and presentations.
                </li>
                <li>
                  <strong>Clear Assessment Criteria:</strong> Provide students
                  with transparent grading rubrics.
                </li>
                <li>
                  <strong>Feedback Loop:</strong> Establish a feedback mechanism
                  for ongoing improvement.
                </li>
              </ol>
              <h3>Program Effectiveness</h3>
              <p>For enhancing program effectiveness:</p>
              <ul>
                <li>Conduct regular program evaluations.</li>
                <li>Collect feedback from students and instructors.</li>
                <li>Review and update curriculum as needed.</li>
              </ul>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
}
}

export default QARecommendations;
