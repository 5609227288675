import React, { useState } from "react";
import "../global-styles.less";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { routes } from "../constants";
const links = [routes.home, routes.login, routes.signup];

function Login() {
  const navigate = useNavigate();
  return (
    <div className="global-styles-ui">
      {" "}
      <div className="pcrpage_container">
        <div className="head">
          <Header links={links} />
          <hr />
        </div>
        <div className="login-container">
          <form action="http://localhost:8080/backend/login.php">
            <fieldset id="info" className="filldetails for_phone">
              <legend>LOGIN</legend>
              <br />
              <label htmlFor="username">U_ID &nbsp;&nbsp;&nbsp;</label>
              <input
              name="email"
                type="text"
                id="username"
                placeholder="Enter your username"
                required
              />
              <br />
              <br />
              <label htmlFor="Password">Password</label>
              <input
                type="password"
                id="Password"
                name="password"
                placeholder="Enter your Password"
                required
              /><br />
              <br />
              <label htmlFor="roleselection">Select your Category</label>
              <select
                id="roleselection"
                className="signselect"
                name="category"
              >
                <option value="student">Student</option>
                <option value="instructor">Instructor</option>
                <option value="admin">Admin</option>
                <option value="qao">Quality Assurance Officer</option>
                <option value="pc">Program Coordinator</option>
              </select>
              <br />
              <div className="remember-me">
                <input type="checkbox" id="remember" />
                <label htmlFor="remember">Remember me</label>
              </div>
              <br />
              <a>
                <p
                  className="forgot-password"
                  onClick={() => {
                    navigate("/forgotpassword");
                  }}
                >
                  Forgot password?
                </p>
              </a>
              <br />
            </fieldset>
            <br />
            {/* <button type="submit">Login</button> */}
           <button type="submit">Login</button>
          </form>
        </div>
      </div>
    </div>
  );
}



export default Login;
